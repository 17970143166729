#login {
    text-align: center;
    display: flex;
    position: fixed;
    overflow: auto;
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
}

#login h1{
    font-family: sofia-pro;
    font-weight: 700;
}

#login .login-body{
    display: block;
    width:100%;
	margin:auto;
}


#login .login-body p{
    font-size: 3vh;
    font-weight: 500;
    font-family: sofia-pro;
}

#login button.btn
{
    color: black;
    background-color: white;
    /* padding: 1rem; */
    /* font-size: 0.8rem; */
    padding: 0.8rem 2rem;
    /* padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 5rem;
    padding-right: 5rem; */
    border: none;
    font-weight: 700;
    text-transform: uppercase;
    min-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}

#login .landing-img{
    height: 8rem;
}

@media only screen and (max-width: 600px) {
    #login .login-body{
        padding: 0 15px;
    }

    #login .landing-img{
        height: 5rem;
    }
  }