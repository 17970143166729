


#canvas a
{
    color: black;
}


#canvas .canvas-tile .img-wrapper .option
{
    opacity: 0;
    width: 2rem;
    height: 2rem;
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
}

#canvas .canvas-tile .img-wrapper:hover .option
{
    opacity: 1;
}


.canvas-tile
{
    min-height: 300px;
    
    border-radius: 0.5rem;
    /* margin-bottom: 1rem; */
    border: 1px solid #ddd;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    height: 90%;
    padding-top: 2rem;
    background-color: #fafafa;
    padding: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.canvas-tile p
{
    font-weight: 400;
    font-size: 0.9rem;
}

.canvas-tile:hover
{
    background-color: #eeeeee;
}

.canvas-tile h5
{
    text-align: center;
    font-size: 1.1rem;
}


/* .canvas-tile p.option-text
{
    text-align: center;
    font-size: 0.85rem;
} */

.canvas-tile>div
{
    height: 100%;
    position: relative;
    /* transform: translate(0, -50%); */


}

.canvas-tile
{
    overflow: scroll;
}


#canvas .comment-tile
{
    min-height: 300px;
    border: 1px solid #ddd;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    height: min-content;
    overflow: hidden;
    /* background-color: #eee; */
    transition: all .2s ease-in-out;
    margin-top: 0rem;
    margin-bottom: 0rem;
    font-weight: 400;
}

#canvas .reply-to
{
    background-color: #eee;
    /* padding: 0px; */
    opacity: 0.8;
}


#canvas .reply-to .canvas-tile
{
    padding: 0px;
}

#canvas .reply-to .canvas-tile img
{
    
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 60%;
    height: auto;
}

#canvas .comment-tile .btn
{
    width: 100%;
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

#canvas .comment-tile p
{
    font-weight: 400;
    font-size: 1.1rem;
    margin-top: 0rem;
    padding: 0rem;
    margin-bottom: 0rem;
}

#canvas .quill p
{
    font-weight: normal;
    font-size: 1rem;
    padding: 0rem;
}

#canvas  .comment-tile-render
{
    overflow: hidden;
    background-color: white;
}

#canvas .user-profile
{
    background-color: transparent;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}

#canvas .user-profile img
{
    height: 2rem;
    border-radius: 2rem;
    width: auto;
    /* margin-right: 1rem; */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
}

#canvas .user-profile .name
{
    font-weight: 400;
    margin-bottom: 0px;
    margin-left: 2rem;
}

#canvas .user-profile .time
{
    font-weight: 400;
    font-size: 0.85rem;
    color: #888888;
    margin-bottom: 0rem;
    margin-left: 2rem;
    margin-top: -0.2rem;
}

#canvas .comment-tile img, #canvas .canvas-tile .img
{
    height: 100%;
    width: auto;
    position: absolute;
    top: -9999px;
    left: -9999px;
    right: -9999px;
    bottom: -9999px;
    margin: auto;
}




#canvas .add-content-tile
{
    min-height: 300px;
    /* background-color: rgba(255,255,255,0.3); */
    border: 1px solid #ddd;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

#canvas .add-content-tile>div
{
    margin-top: 50%;
    transform: translate(0, -50%);
}


@media (min-width: 992px) { 

    #canvas .edit-title a
    {
        display: none;
    }

    #canvas .edit-story a
    {
        display: none;
    }

    #canvas
    {   
        padding: 4rem;
    }

    #canvas .tile-controls
    {
        opacity: 0
    }

    
    #canvas .canvas-tile-wrapper:hover .tile-controls
    {
        opacity: 1
    }

    #canvas .canvas-tile-wrapper:active .tile-controls
    {
        opacity: 1
    }

    #canvas .comment-tile .img-wrapper .maximizeButton
    {
        opacity: 0;
    }

    #canvas .comment-tile .img-wrapper:hover .maximizeButton
    {
        opacity: 1;
    }

 }

#canvas .tile-controls
{
    color: #666;
    font-weight: 100;
    text-align: center;
}

#canvas .tile-controls a
{
    text-align: right;
    cursor: pointer;
}

#canvas .tile-controls a:hover
{
    color: black;
}

#canvas .tile-controls a:active
{
    color: black;
}


#canvas .reply-to
{
    min-height: 300px;
    overflow: hidden;
}


#canvas .reply-to h6
{
    font-family: sofia-pro, sans-serif;
    /* font-weight: 700; */
    color: black;
    padding: 1rem;
    font-size: 1.2rem;
}

#canvas .reply-to .canvas-tile
{
    height: 70%;
    min-height: 0px;
}

#canvas .next-steps p 
{
    font-weight: 200;
    font-size: 0.85rem;
}


#canvas .next-steps .btn 
{
    font-weight: 200;
    font-size: 0.85rem;
    padding: 0.5rem;
}

#canvas .canvas-sketch
{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: transparent;
}

#canvas .comment-tile .img-wrapper
 {
     overflow:hidden
 }

.comment-container:hover .comment-features{
    opacity: 1;
}

.comment-container .comment-features{
    opacity: 0;
}

.quill-nonedit .ql-toolbar{
    display:none;
}

.quill-edit .ql-container{
    border:none!important;
}

.quill-nonedit .ql-container{
    border:none!important;
}

.ql-editor{
    font-family: sofia-pro-soft, sans-serif!important;
}

/* spinner */
.css-1e7x1kh, .css-j7qovq, .css-1da03c{
width:1rem!important;
height: 1rem!important;
}

/* quill editor */
.ql-editor p{
font-family: sofia-pro;
}

/* SPINNER */
.css-sc2rit{
    display: flex;
    width: 100%;
}

.comment-tile .line-clamp{
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.approveCommentNotification
{
    padding: 0.5rem;
    /* padding-top: 1rem; */
    top: -0.25rem;
    background-color: rgb(255, 250, 221);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid rgb(221, 221, 221);
}

.approveCommentNotification > div
{
    display: flex;
    align-items: center;
}

.approveCommentNotification p
{
    /* margin-bottom: 0rem; */
}

.approveCommentNotification .approve-btn
{
    border: 2px solid #000;
    font-size: 0.85rem;
    color: #000;
    background-color: transparent;
    background: linear-gradient(to right, transparent 50%, black 50%);
    background-size: 200% 100%;
    background-position:left bottom;
}

.approveCommentNotification .approve-btn:hover
{
    color: white;
    background-position:right bottom;
    transition: all 0.2s ease-in;
}

.approveCommentNotification p
{
    font-size: 0.85rem;
}


.comment-features
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFEF6;
    border: 1px solid #D7D7D7;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0px 0px 5px 5px;
    margin-top: -0.5rem;
    font-weight: 400;
}

.comment-features .share-comment
{
    border: 1px solid #959595;
    border-radius: 2px;
    padding: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;

}

#canvas .comment-features .share-comment
{
    color: #000;
    background-color: transparent;
    background: linear-gradient(to right, transparent 50%, black 50%);
    background-size: 200% 100%;
    background-position:left bottom;
}

#canvas .comment-features .share-comment:hover
{
    color: white;
    background-position:right bottom;
    transition: all 0.2s ease-in;
    text-decoration: none
}

#canvas .ql-snow .ql-editor img
{
	position: relative;
	top: 0;
	height: auto;
	left: 0
}
