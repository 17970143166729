#landing {
    text-align: center;
    display: flex;
    position: fixed;
    overflow: auto;
    width: 100%;
    height: 100%;
    font-family: sofia-pro
    /* background-color: black; */
    /* color: white; */
}

#landing .landing-body{
    display: block;
    width:100%;
    margin:auto;
}

#landing button.btn
{
    color: white;
    background-color: black;
    /* padding: 1rem; */
    /* font-size: 0.8rem; */
    padding: 0.8rem 2rem;
    /* padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 5rem;
    padding-right: 5rem; */
    border: none;
    font-weight: 700;
    text-transform: uppercase;
    min-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}

.landing-login {
    position: fixed;
    top: 0;
    right: 0;
    padding: 0.5rem 2rem;
    /* font-size: 3vh; */
    font-weight: 500;
    font-family: sofia-pro;
    cursor: pointer;
}

.landing-naya {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0rem 2rem;
    font-weight: 600;
    font-size: 1.7rem;
    font-family: bigmoore,serif;
    cursor: pointer;
}

#landing .landingimg {
    height: 12rem;
    margin: 2rem;
}

@media only screen and (max-width: 600px) {
    #landing .landing-body{
        padding: 0 15px;
        padding-bottom: 15%;
        margin-top: 15%;
    }

  }